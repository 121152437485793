import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { UserService } from '../../services/user.service';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { WorkspaceService } from '../../services/workspace.service';
import { FormsModule } from '@angular/forms';
import { UserStatusComponent } from '../../components/user/user-status/user-status.component';
import { UserInterface, WorkspaceInterface } from 'voxr-shared';
import { TransferAvailabilityComponent } from '../../components/general/transfer-availability/transfer-availability.component';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { WorkspaceRouterLinkDirective } from '../../directives/wRouterLink.directive';
import { AppService } from '../../services/app.service';
import { firstValueFrom, merge } from 'rxjs';

@Component({
  selector: 'app-sidebar-layout',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, RouterLinkActive, TagModule, TooltipModule, ButtonModule,
    DropdownModule, TransferAvailabilityComponent, UserStatusComponent, WorkspaceRouterLinkDirective],
  templateUrl: './sidebar-layout.component.html',
  styleUrl: './sidebar-layout.component.scss'
})
export class SidebarLayoutComponent {
  private app = inject(AppService);
  private userService = inject(UserService);
  private workspaceService = inject(WorkspaceService);
  private route = inject(ActivatedRoute);

  showSidebar = true;
  user?: UserInterface | null;
  workspaces?: WorkspaceInterface[];
  workspace?: WorkspaceInterface | null;
  workspaceRole?: 'owner' | 'admin' | 'agent';
  area?: string;
  needOnboarding?: boolean;
  brand: any;
  logoUrl?: string;

  constructor() {
    merge([this.userService.user$, this.workspaceService.workspaces$, this.workspaceService.workspace$])
      .pipe(takeUntilDestroyed()).subscribe(async () => await this.load());
  }

  async ngOnInit() {
    this.area = this.route.snapshot.data['area'];
    this.brand = await this.app.reseller();
    this.logoUrl = this.brand?.ui?.['logoUrl'] || 'assets/images/logo/voxr-logotype.png';
  }

  externalResellerDomain?: string;
  private async load() {

    this.user = await firstValueFrom(this.userService.user$);
    if (this.user?.resellerConfig?.whiteLabel?.domains?.length)
      this.externalResellerDomain = this.user.resellerConfig.whiteLabel.domains.includes(location.origin) ? undefined : this.user.resellerConfig.whiteLabel.domains[0];

    this.workspaces = (await firstValueFrom(this.workspaceService.workspaces$))?.filter(_ => _.ownerId == this.user?._id || _.members?.find(m => m.userId == this.user?._id));

    this.workspace = await firstValueFrom(this.workspaceService.workspace$);
    if (this.workspace?.ownerId == this.user?._id)
      this.workspaceRole = 'owner';
    else {
      const roles = this.workspace?.members?.find(m => m.userId == this.user?._id)?.roles;
      if (roles?.includes('admin')) this.workspaceRole = 'admin';
      else this.workspaceRole = 'agent';
    }
  }

  async setWorkspace(workspaceId: string) {
    this.workspaceService.setWorkspace(workspaceId);
    // this.workspaceService.workspace$
    //   .pipe(filter(w => w?._id?.toString() == workspaceId)).subscribe(() => window.location.href = '/dashboard');
  }


}

