import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { authInterceptor } from './interceptors/auth.interceptor';
import { environment } from '../environments/environment';
import { errorInterceptor } from './interceptors/error.interceptor';
import { MessageService } from 'primeng/api';
import { SocketIoModule } from 'ngx-socket-io';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { WorkspaceRouterLinkDirective } from './directives/wRouterLink.directive';
import { provideNativeDateAdapter } from '@angular/material/core';


export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(
      withFetch(),
      withInterceptors([authInterceptor, errorInterceptor])
    ),
    importProvidersFrom([
      BrowserAnimationsModule,
      SocketIoModule.forRoot({ url: environment.app.serviceUrl, options: {} }),
    ]), 
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)), 
    provideAuth(() => getAuth()), 
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
    WorkspaceRouterLinkDirective
  ]
};
