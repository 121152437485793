import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { UserService } from './services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../environments/environment';
import { HealthService } from './services/health.service';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom } from 'rxjs';
import { WorkspaceService } from './services/workspace.service';
import { AppService, SSO_REDIRECT_PARAM } from './services/app.service';
import { WhiteLabelConfig } from 'voxr-shared';
import { SafeHtml } from '@angular/platform-browser';
import { BrandLoadingComponent } from "./components/general/brand-loading/brand-loading.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, ToastModule, TooltipModule, BrandLoadingComponent],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private route = inject(ActivatedRoute);
  private app = inject(AppService);
  private userService = inject(UserService);
  private workspaceService = inject(WorkspaceService);
  private healthService = inject(HealthService);

  loading = true;
  deployment = environment.deployment;
  serverId?: Object;
  brand?: WhiteLabelConfig & { resellerId: string; };
  brandCss?: SafeHtml;
  icon!: string;

  constructor() {
    this.checkImpersonate();
    this.userService.user$.pipe(takeUntilDestroyed())
      .subscribe(async user => { await this.load(); });
  }

  async ngOnInit() {
    await this.load();

    this.serverId = await this.healthService.serverId();

  }

  private async load() {
    if (!this.brand) {
      const ssoRedirect = this.route.snapshot.queryParams[SSO_REDIRECT_PARAM];

      this.brand = ssoRedirect ? await this.app.getResellerByOrigin(new URL(ssoRedirect).origin) : await this.app.reseller();

      const { brandCss, icon } = this.app.setBranding(this.brand);
      this.icon = icon;
      this.brandCss = brandCss;
    }

    const user = await firstValueFrom(this.userService.user$);
    if (user) await firstValueFrom(this.workspaceService.workspaces$);
    
    document.querySelector('body')?.classList.add('uw-ready');
    this.loading = false;
  }

  private checkImpersonate(userRoles?: string[] | undefined) {
    if (!location.search.includes('impersonate=')) return;
    localStorage.setItem('impersonate', location.search.split('impersonate=')[1].split('&')[0]);
    // if (!userRoles?.includes('super-admin')) this.appService.logout(true);
    setTimeout(() => location.search = '');
  }


}
